html {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
}

#root {
  height: 100%;
}

#app {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}

header {
  align-content: center;
  text-align: center;
}

header a {
  text-decoration-line: none;
  font-family: monospace;
  font-size: larger;
}

header h1 {
  margin: 0px;
}

#content {
  height: 100%;
  background-color: #fff8f0;
}

body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff8f0;
}
