.body {
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.title {
  text-align: center;
  margin: 0;
  margin-top: 10px;
  padding: 6px;
  font-size: 20px;
}

.horizontal_centering {
  display: flex;
  justify-content: center;
}

.textField input {
  text-align: center;
}


.action {
    position: absolute;
    bottom: 2em;
    right: 2em;
    display: flex;
    justify-content: center;
    flex-direction: row;
    max-width: 300px;
}