.container {
    display: grid;
    justify-content: center;
    max-width: 250px;
}

.buttons {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
}

.text {
    margin: 10px !important;
}

.joinButton {
    margin: 10px !important;
}

.dashboardButton {
    margin: 10px !important;
}