.loginCard {
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.googleButton {

}

