.create {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.create em {
    font-size: xx-small;
    text-align: center;
}

.input {
    margin-top: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    max-width: 250px;
}

.input >* {
    margin: 5px !important;
}

.button {
    margin: 15px !important;
}