.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70%;
}

.invalid {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70%;
}

.valid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.valid h1 {
  color: #353a42;
}

.list {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 4px;
  width: 100% !important;
  max-width: 300px;
  margin: 0px 20px;
}

.list > div {
  height: 30px;
  color: rgba(30, 30, 36, 1) !important;
  user-select: none;
}

.doneButton {
  height: 35px;
  width: 140px;
  margin: 8px 20px !important;
}

.nextButton {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 35px;
  width: calc(100% - 40px) !important;
  max-width: 260px;
  margin: 8px 20px;
  color: rgba(30, 30, 36, 1) !important;
  box-shadow: 2px 6px 10px 2px #36322d;
  background-color: #fff8f0;
  background-color: rgba(255, 248, 240, 0.5);
  user-select: none;
}
