.body {
    display: flex;
    justify-content: center
}

.settingsCard {
    padding: 16px;
    display: grid;
    grid-template-rows: auto 2fr 1fr 1fr 1fr;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    max-width: 500px;
}

.settingsCard h4 {
    margin: 10px;
    text-align: center;
}

.asd {
    display: flex;
    justify-content: center;
}

.radioButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.statsAndShare {
    display: flex;
}

.statsAndShare #stats {
    flex: 1;
}

.statsAndShare #share {
    margin-left: 1em;
    flex: 1;
}