.board {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    justify-content: center;
}

.card strong {
    text-align: right;
    margin-right: 1em;
}

.card a {
    margin: 5px;
    text-align: center;
}

.card {
    justify-content: center;
    box-shadow: 0px 3px 5px 0px #36322d;
    margin: 5px;
    padding: 5px;
    border-radius: 5px;
    height: fit-content;
    width: fit-content;
}

.info {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.buttons button {
    margin: 5px;
    font-size: small;
}
