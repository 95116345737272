.view {
  display: grid;
  grid-template-rows: 1fr 2fr;
  height: 100%;
}

.join {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  -webkit-box-shadow: 0px 5px 17px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 5px 17px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 5px 17px 0px rgba(0, 0, 0, 0.75);
}

.create {
  display: flex;
  padding: 5vh;
  align-items: center;
  color: white;
  flex-direction: column;
}

.buttons {
  display: flex;
  justify-content: space-around;
  justify-content: space-evenly;
  align-items: center;
}

.homecontent {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.curtain {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  background: #282c34;
  transition: height 0.5s;
}
