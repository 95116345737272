.listItem {
  padding: 0 !important;
  padding-left: 10px !important;
  background-color: #fff8f0;
  box-shadow: 0px 3px 10px 0px #36322d;
  margin: 4px 20px;
  border-radius: 4px;
  text-align: center !important;
  height: 35px;
  color: #111d4a;
  width: 100% !important;
  max-width: 300px;
}

.list span {
  display: flex;
  justify-content: center;
}

.released {
  color: #fff8f0;
  transition: background-color 0.5s, color 0.5s;
  background-color: #1e1e24;
}

.dragger {
  margin-right: 5px;
  margin-left: 5px;
}
